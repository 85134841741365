<template>
  <div>
    <!-- breadcrumb面包屑导航区域 -->
    <!-- 首页/活动管理/活动列表/活动详情 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>退单审批</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="orderNo">
            <div style="display: inline-block" class="divSpan">订单编号：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入搜索的编号"
                v-model.trim="queryinfo.orderNo"
                clearable
                @clear="getList"
                style="width: 180px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="returntime">
            <div style="display: inline-block" class="divSpan">退单时间：</div>
            <div style="display: inline-block">
              <el-date-picker
                type="datetimerange"
                v-model="queryinfo.returntime"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
                @change="dateSerach"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getList"
            >搜索</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="OrderList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="note"
            label="订单编号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="下单用户"
            sortable
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="note"
            label="退单说明"
            min-width="150px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="note"
            label="退单时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" width="200px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewDetail(scope.row)"
                >查看详情</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="ApproveRecord(scope.row)"
                >审批单据</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      queryinfo: {
        returntime: '',
        orderNo: '',
        pageNum: 1,
        pageSize: 6
      },
      total: 0,
      OrderList: [
        {
          name: '222'
        }
      ],

      // 日期:选择今天以及之后的日期
      // pickerOptions: {
      //   disabledDate: time => {
      //     return time.getTime() < Date.now() - 8.64e7
      //   }
      // },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () { },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dateSerach () {
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },
    viewDetail () { },
    ApproveRecord () { },
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 40px;
}
</style>